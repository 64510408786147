import React from 'react'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'

const CommunityPage = () => {
  return (
    <Layout hero={<HeroImage title="Tick of Approval" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row mt-4">
        <Col xs={10}>
          <Row className="d-flex align-items-center">
            <Col sm={6}>
              <StaticImage 
                src="../../images/logo--safe-farming-approval.svg" 
                alt="Safe farming logo"
                width={206}
                height={82}
                placeholder="blurred" />
            </Col>
            <Col sm={6}>
              <p className="mb-3">
                Metalcorp's livestock handling equipment has received a Safe Farming Tick Of Approval by the <strong>National Centre for Farmer Health. </strong> 
                A first for the rural sector, the independent report contains the results of Metalcorp's CLASSIC PLUS® Crush, 
                CATTLEMASTER® Crush and the HOBBYMASTER® Vet Crush, which were physically assessed.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-5 d-flex justify-content-center align-item-center blue-row-full content-row">
        <Col xs={10}>
          <Row className="d-flex align-items-center">
            <Col sm={4} className="d-flex justify-content-center">
              <div className="title-container">
                <h3 className="title">SUPERIOR SAFETY</h3>
              </div>
            </Col>
            <Col sm={4} className="d-flex justify-content-center">
              <div className="title-container">
                <h3 className="title">WELL DESIGNED</h3>
              </div>
            </Col>
            <Col sm={4} className="d-flex justify-content-center">
              <div className="title-container">
                <h3 className="title">EASY TO USE</h3>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-5 d-flex justify-content-center align-item-center content-row">
        <Col xs={10}>
          <p>
            With these important jobs to be carried out, the report revealed that Metalcorp's Cattle Crushes meet manual handling demands and 
            gates and locking mechanisms were considered reasonable, easy to use and well designed.
          </p>
          <p>
            The report also revealed that because Metalcorp's Cattle Crushes are designed with rectangular steel sections, 
            this prevents the handler putting an arm in the cattle crush area and is viewed as a significant improvement 
            from cattle crushes designed with round pipe sections.
          </p>
          <p>
            Metalcorp's painted Cattle Crushes were considered to be a superior safety option for farmers, 
            as it was clearly labelled what the levers and locking mechanisms were. 
            This review by the NCFH has prompted Metalcorp to make the painted option as the standard across all models.
          </p>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center content-row mb-4">
        <Col xs={10}>
          <Row className="d-flex align-items-center">
            <Col sm={6}>
              <StaticImage 
                src="../../images/workoutlook.jpg" 
                alt="IMPROVING FARMER SAFETY AND WELLBEING"
                width={480}
                height={360}
                placeholder="blurred" />
            </Col>
            <Col sm={6}>
              <div className="title-container mb-3">
                <h1 className="title">IMPROVING FARMER SAFETY AND WELLBEING</h1>
              </div>
              <p>
                With Metalcorp's Cattle Crushes proven to effectively restrain the typical range of cattle in Australia, 
                farmers are being urged to look for the Safe Farming Tick Of Approval when purchasing their next Cattle Crush. 
                For more information on Metalcorp's Safe Farming Tick of Approval please download our factsheet.
              </p>
              <Link className="button primary" to="/products/cattle-handling/cattlemasterr-crush">
                Shop Cattle Crushes
                <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default CommunityPage

export const Head = () => <title>Tick of Approval | Metalcorp</title>
